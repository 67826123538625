import React, { useEffect, useState, useRef } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Container from "react-bootstrap/Container";
import LoginModal from "../Modals/login_modal";
import CartModal from "../Modals/cart_modal";
import Modal from "react-bootstrap/Modal";
import MenuModal from "../Modals/menu_modal";
import { ApiService } from "../../Components/Services/apiservices";
import HeaderMenu from "../Elements/header_menu";
import sessionCartData from "../../Components/Elements/cart_session_data";
import BannerModal from "../Modals/banner_modal";
import { useNavigate } from "react-router-dom";

function Header({ cartCount = 0, state = "" }) {
  const didMountRef = useRef(true);
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleChildData = (status) => {
    setShow(status);
  };
  const [showCart, setShowCart] = useState(false);
  const handleShowCart = () => setShowCart(true);
  const handleChildCartData = (status) => {
    setShowCart(status);
  };
  const [cartcounttest, setcartcounttest] = useState(cartCount);
  const handleClose = () => {
    setShowCart(false);
  };

  const [showMenuCart, setShowMenuCart] = useState(false);
  const handleShowMenuCart = () => setShowMenuCart(true);
  const handleChildMenuCartData = (status) => {
    setShowMenuCart(status);
  };
  const handleMenuClose = () => {
    setShowMenuCart(false);
  };
  const [settingData, setSettingData] = useState([]);
  const [settingImagePath, setSettingImagePath] = useState("");
  const [setSession, SetSession] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [headerdata, setHeaderData] = useState({});
  const [popupData, setPopupData] = useState({});
  const [mobilePopup, setMobilePopup] = useState({})
  const [imageUrl, setImageUrl] = useState("");
  const [showBanner, setShowBanner] = useState();
  const handleBannerClose = () => {
    setShowBanner(false);
  };
  useEffect(() => {
    if (didMountRef.current) {
      getbannerData()
      getheaderdata();
      getSettingsData();
      SetSession(localStorage.getItem("USER_TOKEN"));
    }
    didMountRef.current = false;
    if (isBrowser) {
      const handleScroll = () => {
        if (window.scrollY > 200) {
          document.getElementById("header_top").classList.add("fixed-head");
          const navbarHeight =
            document.querySelector(".fixed-header").offsetHeight;
          document.body.style.paddingTop = navbarHeight + "px";
        } else {
          document.getElementById("header_top").classList.remove("fixed-head");
          document.body.style.paddingTop = "0";
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const getbannerData = () => {
    ApiService.fetchData("popup-banner").then((res) => {
      if (res.status === "success") {
        setPopupData(res.resPopupBannerData);
        setImageUrl(res.slider_image_path);
        setMobilePopup(res.resPopupBannerMobileData);

        if (res.resPopupBannerData && localStorage.getItem("MODALOPEN") !== "FALSE") {
          setShowBanner(true);
        } else {
          setShowBanner(false);
        }

        localStorage.setItem("MODALOPEN", "FALSE");
      } else {
        setShowBanner(false);
      }
    });
  };

  const getSettingsData = () => {
    ApiService.fetchData("settings").then((res) => {
      if (res.status == "success") {
        setSettingData(res.sitesettings);
        setSettingImagePath(res.setting_image_path)
      }
    });
  };
  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setSearchTerm("");
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length >= 2) {
        fetchSearchResults();
      }
    }, 300); // Debounce time (adjust as needed)

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const getheaderdata = () => {
    ApiService.fetchData("header-data").then((res) => {
      if (res.status === "success") {
        setHeaderData(res.headerdata);
      }
    });
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchSearchResults = () => {
    const dataString = {
      query: searchTerm,
    };
    ApiService.postData("getsearchdata", dataString).then((res) => {
      if (res.status === "success") {
        setSearchResults(res.data);
      } else {
      }
    });
  };
  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/search')
  }
  return (
    <>
      <BrowserView>
        {headerdata?.header_top ? (
          <div className="top-header">
            <Container>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-6">
                  <marquee>{headerdata.header_top}</marquee>
                </div>
              </div>
            </Container>
          </div>
        ) : null}
        <header className="header">
          <div className={
            state == "" ? "fixed-header header-middle" : " fixed-header header-middle " + state
          }
            id="header_top">
            <Container>
              <div className="header-left">
                <a href="/" className="logo me-5">
                  <img
                    src={settingData.logo != null ? settingImagePath + settingData.logo : "/img/logodefault.png"} alt="heartswithfingerslogo"
                    width={125}
                    height={52}
                  />
                </a>
                <div className="header-search" onBlur={handleBlur}>
                  <form action="#" className="input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      autoComplete="off"
                      placeholder="Search for products, brands & more..."
                      required=""
                      onChange={handleInputChange}
                      value={searchTerm}
                    />
                    {searchResults &&
                      searchResults.length > 0 &&
                      searchTerm.trim() !== "" ? (
                      <div className="header-search-list" id="search_input">
                        <ul>
                          {searchResults.map((value, index) => (
                            <a href={value.redirect}>
                              <li key={index}>{value.name}</li>
                            </a>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                    <button
                      className="btn btn-search"
                      type="submit"
                      title="submit-button"
                    >
                      <i className="d-icon-search"></i>
                    </button>
                  </form>
                </div>
              </div>
              <div className="header-center">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <ul className="navbar-nav">
                    <li className="nav-item"><a className="nav-link" href="/meet-the-makers">Meet the Makers</a></li>
                    <li className="nav-item dropdown dropdown-hover">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        About
                      </a>
                      <ul className="dropdown-menu submenu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="/who-we-are">Who We Are</a></li>
                        <li><a className="dropdown-item" href="/our-story">Our Story</a></li>
                        <li><a className="dropdown-item" href="/blogs">Blog</a></li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown dropdown-hover">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Connect
                      </a>
                      <ul
                        className="dropdown-menu submenu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a className="dropdown-item" href="/career">
                            Career
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/feedback-form">
                            Feedback Form
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/contact-us">
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-right justify-content-end">
                <ul className="header-right-list">
                  {setSession ? (
                    <>
                      <li>
                        <a href="/account/wishlist">
                          <i class="ri-heart-3-line"></i>
                          <span>Favorites</span>
                        </a>
                      </li>
                      <li>
                        <a href="/account/account-overview">
                          <i class="ri-user-line"></i>
                          <span>Account</span>
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={handleShow}
                        ><i className="ri-heart-3-line">
                          </i><span>Favorites</span>
                        </a>
                      </li>
                      <li><a
                        href="javascript:void(0)"
                        onClick={handleShow}
                      >
                        <i className="ri-user-line">
                        </i><span>Account</span></a></li>
                    </>
                  )}

                  <li className="cart-toggle">
                    <a href="javascript:void(0)"
                      onClick={handleShowCart}
                    >
                      <i className="ri-shopping-bag-3-line"></i>
                      <span>Cart</span>
                      {showCart ? (
                        <div className="cart-count">{cartCount}</div>
                      ) : (
                        <div className="cart-count">{parsedCartSession.length}</div>
                      )}
                    </a>
                  </li>
                </ul>

              </div>
            </Container>
          </div>
          <div className="header-bottom">
            <Container>
              <HeaderMenu />
            </Container>
          </div>
        </header>
      </BrowserView>
      <MobileView>
        {headerdata?.header_top ? (
          <div className="top-header">
            <Container>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-12 ">
                  <marquee>{headerdata.header_top}</marquee>
                </div>
              </div>
            </Container>
          </div>
        ) : null}
        <header className="mobileheader">
          <div className="mobileheaderTop">
            <div className="container">
              <div className="mobileheaderLeft">
                <a href="javascript:void(0)" className="sidenav-trigger" onClick={handleShowMenuCart}><i className="ri-menu-line"></i></a>
                <a href="/"><img src="/img/logo.png" alt="heartswithfingerslogo" width="90" height="34" /></a>
              </div>
              <div className="mobileheaderRight justify-content-end">
                <ul className="header-right-list">
                  {setSession ? (
                    <>
                      <li>
                        <a
                          href="/account/account-overview"
                          title="login"
                        >
                          <i class="ri-user-line"></i>
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <a
                          href="javascript:void(0)"
                          title="login"
                          onClick={handleShow}
                        >
                          <i class="ri-user-line"></i>
                        </a>
                      </li>
                    </>
                  )}
                  <li className="cart-toggle">
                    <a href="javascript:void(0)" onClick={handleShowCart}><i className="ri-shopping-bag-3-line"></i><div className="cart-count">{cartCount}</div></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mobileheaderBottom">
            <div className="container">
              <div className="mobileheader-search">
                <div className="mobileheader-search-inner" onClick={handleClick}>
                  <i className="d-icon-search me-3"></i>
                  Search for products, brands & more...
                </div>
              </div>
            </div>
          </div>
        </header>

      </MobileView>
      {show && <LoginModal showmodal={show} onChildData={handleChildData} />}
      <Modal show={showCart} onHide={handleClose} className="right cart-modal">
        {showCart && (
          <CartModal
            showcartmodal={showCart}
            onChildCartData={handleChildCartData}
          />
        )}
      </Modal>
      <Modal
        show={showMenuCart}
        onHide={handleMenuClose}
        className="left menu-modal"
      >
        {showMenuCart && (
          <MenuModal
            showMenucartmodal={showMenuCart}
            onChildCartData={handleChildMenuCartData}
          />
        )}
      </Modal>


      {showBanner && popupData && (
        <BannerModal
          showBanner={showBanner}
          mobilePopup={mobilePopup}
          popupData={popupData}
          imageUrl={imageUrl}
          onClose={handleBannerClose}
        />
      )}

    </>
  );
}
export default Header;
