import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserView, MobileView } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import constant from "../../Components/Services/constant";
function CategoryHtml({ indexLoop, onParentData, dataObj }) {
    const didMountRef = useRef(true);
    const [loading, setLoading] = useState();
    const [homeCategoryData, setHomeCategoryData] = useState([]);
    const [setSession, SetSession] = useState("");
    const [baseUrl, setBaseUrl] = useState("");
    useEffect(() => {
        if (didMountRef.current) {
            SetSession(localStorage.getItem("USER_TOKEN"));
            getHomeCategoryData();
        }
        didMountRef.current = false;
    }, []);
    const getHomeCategoryData = () => {
        setLoading(true);
        const dataString = {
            data: dataObj,
        };
        ApiService.postData("home-category-data", dataString).then((res) => {
            if (res.status == "success") {
                setBaseUrl(res.baseUrl)
                setHomeCategoryData(res.resCategoryData);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    };
    return (
        <>
            {loading == true ? (
                <>
                    <BrowserView>
                        <div className="row g-2">
                            {[...Array(4)].map((_, index) => (
                                <div key={index} className="col-lg-3 col-6">
                                    <div className="product">
                                        <figure className="product-media">
                                            <Skeleton variant="text" width={300} height={250} />
                                        </figure>
                                        <div className="product-details">
                                            <h3 className="product-name">
                                                <Skeleton variant="text" width={150} />
                                            </h3>
                                            <div className="product-price">
                                                <Skeleton variant="text" width={150} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className="row g-2">
                            {[...Array(4)].map((_, index) => (
                                <div key={index} className="col-lg-3 col-6">
                                    <div className="product">
                                        <figure className="product-media">
                                            <Skeleton variant="text" width={300} height={160} />
                                        </figure>
                                        <div className="product-details">
                                            <h3 className="product-name">
                                                <Skeleton variant="text" width={150} />
                                            </h3>
                                            <div className="product-price">
                                                <Skeleton variant="text" width={150} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </MobileView>
                </>
            ) : (
                <>
                    <BrowserView>
                        {dataObj.home_display_type === 2 ? (
                            <>
                                {dataObj.home_product_name ? <div className="page-title mb-4">
                                    <h2 className="mb-0">{dataObj.home_product_name}</h2>
                                </div> : null}
                                <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                                    {homeCategoryData.map((subvalue, indexProduct) => {
                                        return (
                                            <a href={"/collection/category/" + subvalue.cat_slug} className="ccategory" key={indexProduct}>
                                                <div className="ccategory-icon"><img src={subvalue.cat_image != null ? baseUrl + subvalue.cat_image : constant.DEFAULT_IMAGE} alt="heartswithfingers" width={20}></img></div>
                                                <div className="ccategory-name">{subvalue.cat_name}</div>
                                            </a>
                                        );
                                    })}
                                </div>
                            </>
                        ) : (
                            <>
                                {dataObj.home_product_name ? <div className="page-title mb-4">
                                    <h2 className="mb-0">{dataObj.home_product_name}</h2>
                                </div> : null}
                                <Swiper
                                    loop={true}
                                    spaceBetween={15}
                                    navigation={{
                                        nextEl: ".swiper-button-next",
                                        prevEl: ".swiper-button-prev",
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                        600: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                        1000: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                    }}
                                >
                                    {homeCategoryData.map((subvalue, indexProduct) => {
                                        return (
                                            <SwiperSlide key={indexProduct}>
                                                <a href={"/collection/category/" + subvalue.cat_slug} className="ccategory">
                                                    <div className="ccategory-icon"><img src={subvalue.cat_image != null ? baseUrl + subvalue.cat_image : constant.DEFAULT_IMAGE} alt="heartswithfingers" width={20}></img></div>
                                                    <div className="ccategory-name">{subvalue.cat_name}</div>
                                                </a>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </>
                        )}
                    </BrowserView>
                    <MobileView>
                        {dataObj.home_display_type === 2 ? (
                            <>
                                {dataObj.home_product_name ? <div className="page-title mb-4">
                                    <h2 className="mb-0">{dataObj.home_product_name}</h2>
                                </div> : null}
                                <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                                    {homeCategoryData.map((subvalue, indexProduct) => {
                                        return (
                                            <a href={"/collection/category/" + subvalue.cat_slug} className="ccategory" key={indexProduct}>
                                                <div className="ccategory-icon"><img src={subvalue.cat_image != null ? baseUrl + subvalue.cat_image : constant.DEFAULT_IMAGE} alt="heartswithfingers" width={20}></img></div>
                                                <div className="ccategory-name">{subvalue.cat_name}</div>
                                            </a>
                                        );
                                    })}
                                </div>
                            </>
                        ) : (
                            <>
                                {dataObj.home_product_name ? <div className="page-title mb-4">
                                    <h2 className="mb-0">{dataObj.home_product_name}</h2>
                                </div> : null}
                                <Swiper
                                    loop={true}
                                    spaceBetween={15}
                                    navigation={{
                                        nextEl: ".swiper-button-next",
                                        prevEl: ".swiper-button-prev",
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                        600: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                        1000: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                    }}
                                >
                                    {homeCategoryData.map((subvalue, indexProduct) => {
                                        return (
                                            <SwiperSlide key={indexProduct}>
                                                <a href={"/collection/category/" + subvalue.cat_slug} className="ccategory">
                                                    <div className="ccategory-icon"><img src={subvalue.cat_image != null ? baseUrl + subvalue.cat_image : constant.DEFAULT_IMAGE} alt="heartswithfingers" width={20}></img></div>
                                                    <div className="ccategory-name">{subvalue.cat_name}</div>
                                                </a>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </>
                        )}
                    </MobileView>
                </>
            )}
        </>
    );
}
export default CategoryHtml;
