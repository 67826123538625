import React, { useEffect, useState, useRef, Suspense } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import constant from "../../Components/Services/constant";
import Testimonials from "../../Components/Elements/testimonials";
import Certification from "../../Components/Elements/certification";
import CategoryWiseProducts from "../../Components/Elements/category_wise_products";
import TagWiseProducts from "../../Components/Elements/tag_wise_products";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import HomeTopBanner from "../../Components/Elements/home_top_banner";
import FeaturedCategories from "../../Components/Elements/featured_categories";
import GridBannerFirst from "../../Components/Elements/grid_banner_first";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import CategoryHtml from "../../Components/Elements/category_html";
import VideoProductsHtml from "../../Components/Elements/video_products";
import BlogHtml from "../../Components/Elements/blog_section";
import { ToastContainer } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';
function Home() {
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const didMountRef = useRef(true);
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const [cartCount, setCartCount] = useState(parsedCartSession.length);
  const [pageData, setPageData] = useState("")
  const [sectionData, setSectionData] = useState([])
  const [mobileSectionData, setMobileSectionData] = useState([])
  const [mediaImageUrl, setMediaImageUrl] = useState("")
  const [headerImageUrl, setHeaderImageUrl] = useState("")
  const [pageContent, setPageContent] = useState("");

  useEffect(() => {
    if (didMountRef.current) {
      getDashboardData();
      setTimeout(() => {
        setSpinnerLoading(false);
      }, 1000);

      const getPageData = {
        slug: "home",
      };
      ApiService.postData("page-content", getPageData).then((res) => {
        if (res.status === "success") {
          setPageData(res.data)
          setPageContent(res.data.page_content);
          setHeaderImageUrl(res.page_header_image_path)
        } else {

        }
      });
    }
    didMountRef.current = false;
  }, []);
  const getDashboardData = () => {
    ApiService.fetchData("dashboard").then((res) => {
      if (res.status === "success") {
        setMediaImageUrl(res.mediaImageUrl)
        setSectionData(res.resAppearanceHomeData)
        setMobileSectionData(res.resAppearanceHomeMobileData)
      }
    });
  };
  const handleCartCount = (status) => {
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    setCartCount(parsedCartSession.length)
  };
  const convertCssStringToObject = (cssString) => {
    if (!cssString) {
      return {};
    }

    return cssString
      .split(';')
      .filter(style => style.trim())
      .reduce((styleObject, stylePropertyValue) => {
        let [property, value] = stylePropertyValue.split(':');
        if (!property || !value) return styleObject;
        property = property.trim().replace(/(-[a-z])/g, match => match.toUpperCase().replace('-', ''));
        value = value.trim();
        styleObject[property] = value;
        return styleObject;
      }, {});
  }

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Hearts with Fingers"}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : "Hearts with Fingers"} />
        {pageData.page_meta_keyword != null ? <meta name="keywords" content={pageData.page_meta_keyword} /> : "Hearts with Fingers"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData?.page_meta_title != null ? pageData.page_meta_title : "Hearts with Fingers"} />
        {pageData?.page_header_image ? (
          <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
        ) : (
          <meta
            property="og:image"
            content={constant.FRONT_URL + 'assets/images/logo.png'}
          />
        )}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : "Hearts with Fingers"} />
        <meta name="twitter:title" content={pageData?.page_meta_title != null ? pageData.page_meta_title : "Hearts with Fingers"} />
        <meta name="twitter:description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : "Hearts with Fingers"} />
        {pageData?.page_header_image ? (
          <meta property="twitter:image" content={headerImageUrl + pageData.page_header_image} />
        ) : (
          <meta
            property="twitter:image"
            content={constant.FRONT_URL + 'assets/images/logo.png'}
          />
        )}
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
      <Header cartCount={cartCount} />
      <BrowserView>
        <HomeTopBanner />
        <section class="sectiontop-gap-medium sectionbottom-gap-small">
          <div class="container">
            <div class="row null">
              <div class="col-lg-12">
                <div class="page-title page-title-center mb-4"><h2 class="mb-0">Farm to plate: Wholesome recipes from the heart</h2></div>
                <div class="row g-3 cols-xl-6">
                  <div class="cproduct cproductVideo">
                    <div class="cproduct-media">
                      <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/achari_paneer.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                    </div>
                  </div>
                  <div class="cproduct cproductVideo">
                    <div class="cproduct-media">
                      <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/dal_bati.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                    </div>
                  </div>
                  <div class="cproduct cproductVideo">
                    <div class="cproduct-media">
                      <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/green_moong_dal_chila.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                    </div>
                  </div>
                  <div class="cproduct cproductVideo">
                    <div class="cproduct-media">
                      <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/kada_prasad_ghee.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                    </div>
                  </div>
                  <div class="cproduct cproductVideo">
                    <div class="cproduct-media">
                      <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/mustard_oil.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                    </div>
                  </div>
                  <div class="cproduct cproductVideo">
                    <div class="cproduct-media">
                      <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/sauf_honey.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {sectionData.length > 0 && (
          sectionData.map((valueSection, indexSection) => (
            <section className={valueSection.home_top_spaced + " " + valueSection.home_bottom_spaced} key={indexSection} style={convertCssStringToObject(valueSection.home_inline_style)}>
              <div className={valueSection.home_sec_layout_type}>
                <div className={"row  " + valueSection.home_grid}>
                  {valueSection.has_many_home_inner.length > 0 &&
                    valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                      <><div className={"col-lg-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                        {valueRowInner.has_many_home_details.length > 0 &&
                          valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                            let dataObj;
                            if (valueRow.home_type === 1) {
                              if (valueRow.home_layout_for_type === 1) {
                                dataObj = {
                                  'id': valueRow.home_cat_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                  <CategoryWiseProducts
                                    key={indexRow}
                                    onParentData={handleCartCount}
                                    dataObj={dataObj}
                                  />
                                );
                              } else {
                                dataObj = {
                                  'id': valueRow.home_tag_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                  <TagWiseProducts
                                    key={indexRow}
                                    onParentData={handleCartCount}
                                    dataObj={dataObj}
                                  />
                                );
                              }
                            } else if (valueRow.home_type === 2) {
                              dataObj = {
                                'id': valueRow.home_tag_id,
                                'home_product_name': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_no_items': valueRow.home_no_items,
                                'home_col_grid': 'cols-xl-',
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                              };
                              return (
                                <CategoryHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 3) {
                              return (
                                <p key={indexRow}></p>
                              );
                            } else if (valueRow.home_type === 4) {
                              return (
                                <div key={indexRow}>
                                  <p>{valueRow.home_text_title}</p>
                                  <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                </div>
                              );
                            } else if (valueRow.home_type === 5) {
                              dataObj = {
                                'home_image_link': valueRow.home_image_link,
                                'home_image_url': valueRow.home_image_url,
                                'home_image': valueRow.home_image,
                                'spinnerLoading': spinnerLoading,
                              };
                              return (
                                <GridBannerFirst
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 6) {
                              dataObj = {
                                'home_image_link': valueRow.home_image_link,
                                'home_image_url': valueRow.home_image_url,
                                'home_image': valueRow.home_image,
                                'spinnerLoading': spinnerLoading,
                              };
                              return (
                                <div className="swipergallery">
                                  <Swiper
                                    loop={true}
                                    spaceBetween={valueRow.home_space_between_slide}
                                    navigation={{
                                      nextEl: ".swiper-button-next",
                                      prevEl: ".swiper-button-prev",
                                    }}
                                    breakpoints={{
                                      0: {
                                        slidesPerView: valueRow.home_column,
                                      },
                                      600: {
                                        slidesPerView: valueRow.home_column,
                                      },
                                      1000: {
                                        slidesPerView: valueRow.home_column,
                                      },
                                    }}>
                                    {valueRow.has_many_gallery && valueRow.has_many_gallery.length > 0 && valueRow.has_many_gallery.map((galleryValue, indexgallery) => {
                                      return (
                                        <SwiperSlide key={indexgallery}>
                                          <div key={indexgallery}>
                                            <img src={galleryValue.home_gallery_image_url != null ? mediaImageUrl + galleryValue.home_gallery_image_url : constant.DEFAULT_IMAGE} alt='hearts with fingers' title='gallery'></img>
                                          </div>
                                        </SwiperSlide>
                                      );
                                    })}
                                  </Swiper>
                                </div>
                              );
                            } else if (valueRow.home_type === 7) {
                              dataObj = {
                                'id': valueRow.home_tag_id,
                                'home_product_name': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_no_items': valueRow.home_no_items,
                                'home_col_grid': 'cols-xl-',
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                              };
                              return (
                                <VideoProductsHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 8) {
                              dataObj = {
                                'home_blog_title': valueRow.home_blog_title,
                                'home_blog_sub_title': valueRow.home_blog_sub_title,
                                'home_blog_layout_type': valueRow.home_blog_layout_type,
                                'home_column': valueRow.home_column,
                                'home_col_grid': 'cols-xl-',
                              };
                              return (
                                <BlogHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 10 && valueRow.home_video_block) {
                              return (
                                <div className="videosection">
                                  <video src={valueRow.home_video_block}
                                    autoPlay="autoplay"
                                    loop
                                    muted
                                    playsInline></video>
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-lg-12">

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </div></>
                    ))}
                </div>
              </div>
            </section>
          ))
        )}


        <Testimonials />
        <Certification />
      </BrowserView>
      <MobileView>
        <HomeTopBanner />
        <section>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="page-title mb-4 mt-4"><h2 class="mb-0 tx-14">Farm to plate: Wholesome recipes from the heart</h2></div>
                <Swiper
                  loop={true}
                  spaceBetween={15}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: '2',
                    },
                    600: {
                      slidesPerView: '2',
                    },
                    1000: {
                      slidesPerView: '2',
                    },
                  }}>
                  <SwiperSlide>
                    <div class="cproduct">
                      <div class="cproduct-media">
                        <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/achari_paneer.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="cproduct">
                      <div class="cproduct-media">
                        <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/dal_bati.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="cproduct">
                      <div class="cproduct-media">
                        <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/green_moong_dal_chila.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="cproduct">
                      <div class="cproduct-media">
                        <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/kada_prasad_ghee.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="cproduct">
                      <div class="cproduct-media">
                        <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/mustard_oil.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="cproduct">
                      <div class="cproduct-media">
                        <figure class="cproduct-media-img"><video src={constant.BASE_URL+"/public/videos/sauf_honey.mp4"} autoPlay="autoplay" loop muted playsInline></video></figure>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        {mobileSectionData.length > 0 && (
          mobileSectionData.map((valueSection, indexSection) => (
            <section className={valueSection.home_top_spaced + " " + valueSection.home_bottom_spaced} key={indexSection} style={convertCssStringToObject(valueSection.home_inline_style)}>
              <div className={valueSection.home_sec_layout_type}>
                <div className={"row  " + valueSection.home_grid}>
                  {valueSection.has_many_home_inner.length > 0 &&
                    valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                      <><div className={"col-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                        {valueRowInner.has_many_home_details.length > 0 &&
                          valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                            let dataObj;
                            if (valueRow.home_type === 1) {
                              if (valueRow.home_layout_for_type === 1) {
                                dataObj = {
                                  'id': valueRow.home_cat_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_col_grid': 'cols-',
                                };
                                return (

                                  <CategoryWiseProducts
                                    key={indexRow}
                                    onParentData={handleCartCount}
                                    dataObj={dataObj}
                                  />
                                );
                              } else {
                                dataObj = {
                                  'id': valueRow.home_tag_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_col_grid': 'cols-',
                                };
                                return (
                                  <TagWiseProducts
                                    key={indexRow}
                                    onParentData={handleCartCount}
                                    dataObj={dataObj}
                                  />
                                );
                              }
                            } else if (valueRow.home_type === 2) {
                              dataObj = {
                                'id': valueRow.home_tag_id,
                                'home_product_name': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_no_items': valueRow.home_no_items,
                                'home_col_grid': 'cols-',
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                              };
                              return (
                                <CategoryHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 3) {
                              return (
                                <div className={"col-lg-" + valueRow.home_layout_type} key={indexRow}>
                                  <p key={indexRow}></p>
                                </div>
                              );
                            } else if (valueRow.home_type === 4) {
                              return (
                                <div key={indexRow}>
                                  <p>{valueRow.home_text_title}</p>
                                  <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                </div>
                              );
                            } else if (valueRow.home_type === 5) {
                              dataObj = {
                                'home_image_link': valueRow.home_image_link,
                                'home_image_url': valueRow.home_image_url,
                                'home_image': valueRow.home_image,
                                'spinnerLoading': spinnerLoading,
                              };
                              return (
                                <GridBannerFirst
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 6) {
                              dataObj = {
                                'home_image_link': valueRow.home_image_link,
                                'home_image_url': valueRow.home_image_url,
                                'home_image': valueRow.home_image,
                                'spinnerLoading': spinnerLoading,
                              };
                              return (
                                <div className="swipergallery">
                                  <Swiper
                                    loop={true}
                                    spaceBetween={valueRow.home_space_between_slide}
                                    navigation={{
                                      nextEl: ".swiper-button-next",
                                      prevEl: ".swiper-button-prev",
                                    }}
                                    breakpoints={{
                                      0: {
                                        slidesPerView: valueRow.home_column,
                                      },
                                      600: {
                                        slidesPerView: valueRow.home_column,
                                      },
                                      1000: {
                                        slidesPerView: valueRow.home_column,
                                      },
                                    }}>
                                    {valueRow.has_many_gallery && valueRow.has_many_gallery.length > 0 && valueRow.has_many_gallery.map((galleryValue, indexgallery) => {
                                      return (
                                        <SwiperSlide key={indexgallery}>
                                          <div key={indexgallery}>
                                            <img src={galleryValue.home_gallery_image_url != null ? mediaImageUrl + galleryValue.home_gallery_image_url : constant.DEFAULT_IMAGE} alt='hearts with fingers' title='gallery'></img>
                                          </div>
                                        </SwiperSlide>
                                      );
                                    })}
                                  </Swiper>
                                </div>
                              );
                            } else if (valueRow.home_type === 7) {
                              dataObj = {
                                'id': valueRow.home_tag_id,
                                'home_product_name': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_no_items': valueRow.home_no_items,
                                'home_col_grid': 'cols-xl-',
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                              };
                              return (
                                <VideoProductsHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 8) {
                              dataObj = {
                                'home_blog_title': valueRow.home_blog_title,
                                'home_blog_sub_title': valueRow.home_blog_sub_title,
                                'home_blog_layout_type': valueRow.home_blog_layout_type,
                                'home_column': valueRow.home_column,
                                'home_col_grid': 'cols-xl-',
                              };
                              return (
                                <BlogHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 10 && valueRow.home_video_block) {
                              return (
                                <div className="videosection">
                                  <video src={valueRow.home_video_block}
                                    autoPlay="autoplay"
                                    loop
                                    muted
                                    playsInline></video>
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-lg-12">

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </div></>
                    ))}
                </div>
              </div>
            </section>
          ))
        )}

      </MobileView>
      <div className="homeaccord">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h2>Hearts with Fingers - Curating handcrafted treasures from all over India</h2></Accordion.Header>
            <Accordion.Body>

              {pageContent != null ? (
                <section className="sectionbottom-gap-medium" dangerouslySetInnerHTML={{ __html: pageContent }}></section>
              ) : (
                ""
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <Testimonials />
      <Certification />
      <Footer />
    </>
  );
}
export default Home;
