import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "react-loading-skeleton";
import { BrowserView, MobileView } from "react-device-detect";
import ProductBox from "./product_box";

function TagWiseProducts({ indexLoop, onParentData, dataObj }) {
  const didMountRef = useRef(true);
  const [loading, setLoading] = useState();
  const [tagCategoriesData, setTagCategoriesData] = useState([]);
  useEffect(() => {
    if (didMountRef.current) {
      getTagCategoryData();
    }
    didMountRef.current = false;
  }, []);
  const getTagCategoryData = () => {
    setLoading(true);
    const dataString = {
      data: dataObj,
    };
    ApiService.postData("tags-wise-products-new", dataString).then((res) => {
      if (res.status == "success") {
        setTagCategoriesData(res.tagsData);
        setLoading(false);
      }else{
        setLoading(false);
      }
    });
  }; 
 
  return (
    <>
      {loading == true ? (
        <>
          <BrowserView>
            <div className="row g-2">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="col-lg-3 col-6">
                  <div className="product">
                    <figure className="product-media">
                      <Skeleton variant="text" width={300} height={250} />
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <Skeleton variant="text" width={150} />
                      </h3>
                      <div className="product-price">
                        <Skeleton variant="text" width={150} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </BrowserView>
          <MobileView>
            <div className="row g-2">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="col-lg-3 col-6">
                  <div className="product">
                    <figure className="product-media">
                      <Skeleton variant="text" width={300} height={160} />
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <Skeleton variant="text" width={150} />
                      </h3>
                      <div className="product-price">
                        <Skeleton variant="text" width={150} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> 
          </MobileView>
        </>
      ) : (
        <> 
          {tagCategoriesData.map((value, index) => {
            if (value.tagProducts.length > 0) {
              if (dataObj.home_display_type === 2) {
                return (
                  <>
                    <div className="page-title mb-3" key={index}>
                      <h2 className="mb-0">{dataObj.home_product_name}</h2>
                      <a href={"/collection/tag/" + value.tag_slug} className="viewmorebutton">View More <i className="ri-arrow-right-s-line ms-2"></i></a>
                    </div>
                    <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                      {value.tagProducts.map((subvalue, indexProduct) => {
                        return (
                          <>
                            <ProductBox productValue={subvalue} indexProduct={indexProduct} />
                          </>
                        );
                      })}
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="page-title mb-3" key={index}>
                      <h2 className="mb-0">{dataObj.home_product_name}</h2>
                      <a href={"/collection/tag/" + value.tag_slug} className="viewmorebutton">View More <i className="ri-arrow-right-s-line ms-2"></i></a>
                    </div> 
                    <Swiper
                      loop={true}
                      spaceBetween={15}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                      breakpoints={{
                        0: {
                          slidesPerView: dataObj.home_column,
                        },
                        600: {
                          slidesPerView: dataObj.home_column,
                        },
                        1000: {
                          slidesPerView: dataObj.home_column,
                        },
                      }}>
                      {value.tagProducts.map((subvalue, indexProduct) => {
                        return (
                          <SwiperSlide key={indexProduct}>
                            <ProductBox productValue={subvalue} indexProduct={indexProduct} />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </>
                );
              }
            }
          })}
        </>
      )} 
    </>
  );
}
export default TagWiseProducts;
