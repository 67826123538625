import React from 'react';
import moment from "moment";
const Steps = ({ status, date, statustext }) => {
    const stepClasses = `col-3 bs-wizard-step ${
      status === "active" ? "active" : ""
    } ${status === "complete" ? "complete" : ""} ${
      status === "disabled" ? "disabled" : ""
    }`;

    return (
      <div className={stepClasses}>
        <div className="progress">
          <div className="progress-bar"></div>
        </div>
        <a href="#" className="bs-wizard-dot"></a>
        <div className="bs-wizard-info text-center">
          <p className="mb-0">{statustext}</p>
          {date ? (
            <p className="mb-0 tx-12">{moment(date).format("DD MMM YYYY")}</p>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };
const orderStatusComponent = (value) => {
    
    switch (value.td_item_status) {
        case 1:
            return (
                <div className="row bs-wizard mt-1">
                    <Steps
                        status="complete"
                        date={value.created_at}
                        statustext="Order Placed"
                    />
                    <Steps
                        status="disabled"
                        date=""
                        statustext="Item Picked Up"
                    />
                    <Steps
                        status="disabled"
                        date=""
                        statustext="Shipped"
                    />
                    <Steps
                        status="disabled"
                        date=""
                        statustext="Delivered"
                    />
                </div>
            );
        case 4:
            return (
                <div className="row bs-wizard mt-5">
                    <Steps
                        status="complete"
                        date={value.created_at}
                        statustext="Order Placed"
                    />
                    <Steps
                        status="complete"
                        date={value.td_pickedup_date}
                        statustext="Item Picked Up"
                    />
                    <Steps
                        status="complete"
                        date={value.td_shiped_date}
                        statustext="Shipped"
                    />
                    <Steps
                        status="complete"
                        date={value.td_delivered_date}
                        statustext="Delivered"
                    />
                </div>
            );
        case 5:
            return (
                <div className="row bs-wizard mt-5">
                    <Steps
                        status="complete"
                        date={value.created_at}
                        statustext="Order Placed"
                    />
                    <Steps
                        status="complete"
                        date={value.td_cancellation_date}
                        statustext="Cancelled"
                    />
                </div>
            );
        case 6:
            return (
                <div className="row bs-wizard mt-5">
                    <Steps
                        status="complete"
                        date={value.created_at}
                        statustext="Order Placed"
                    />
                    <Steps
                        status="complete"
                        date={value.td_pickedup_date}
                        statustext="Item Picked Up"
                    />
                    <Steps
                        status="active"
                        date={value.td_shiped_date}
                        statustext="Shipped"
                    />
                    <Steps
                        status="disabled"
                        date=""
                        statustext="Delivered"
                    />
                </div>
            );
        case 7:
            return (
                <div className="row bs-wizard mt-5">
                    <Steps
                        status="complete"
                        date={value.created_at}
                        statustext="Order Placed"
                    />
                    <Steps
                        status="active"
                        date={value.td_pickedup_date}
                        statustext="Item Picked Up"
                    />
                    <Steps
                        status="disabled"
                        date={value.td_shiped_date}
                        statustext="Shipped"
                    />
                    <Steps
                        status="disabled"
                        date={value.td_delivered_date}
                        statustext="Delivered"
                    />
                </div>
            );
        case 2:
            return (
                <div className="row bs-wizard mt-5">
                    <Steps
                        status="complete"
                        date={value.created_at}
                        statustext="Order Placed"
                    />
                    <Steps
                        status="active"
                        date={value.td_pendingpayment_date}
                        statustext="Payment Pending"
                    />
                    <Steps
                        status="disabled"
                        date={value.td_shiped_date}
                        statustext="Shipped"
                    />
                    <Steps
                        status="disabled"
                        date={value.td_delivered_date}
                        statustext="Delivered"
                    />
                </div>
            );
        case 3:
            return (
                <div className="row bs-wizard mt-5">
                    <Steps
                        status="complete"
                        date={value.created_at}
                        statustext="Order Placed"
                    />
                    <Steps
                        status="active"
                        date={value.td_onhold_date}
                        statustext="On Hold"
                    />
                    <Steps
                        status="disabled"
                        date={value.td_shiped_date}
                        statustext="Shipped"
                    />
                    <Steps
                        status="disabled"
                        date={value.td_delivered_date}
                        statustext="Delivered"
                    />
                </div>
            );
        default:
            return null;
    }
};

export default orderStatusComponent;
