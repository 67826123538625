import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useParams } from "react-router-dom";
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { Helmet } from "react-helmet";
import moment from "moment";
function Blog(){
    const { slug } = useParams();
    const { type } = useParams();
    const didMountRef = useRef(true);
    const [pageData, setPageData] = useState({});
    const [pageContent, setPageContent] = useState("");
    const [blogData, setBlogData] = useState([]);
    const [blogImagePath, setBlogImagePath] = useState([]);
    const [blogImageUrl, setBlogImageUrl] = useState("");
    const [headerImageUrl, setHeaderImageUrl] = useState("")
    const [latestBlogData, setLatestBlogData] = useState([]);
    const [latestCategoryData, setLatestCategoryData] = useState([]);
    const [latestBlogImageUrl, setLatestBlogImageUrl] = useState("");
    const [blogListDefaultImage, setBlogListDefaultImage] = useState("");
    const [defaultImage, setDefaultImage] = useState("");
    useEffect(() => {
            if (didMountRef.current) {
            
              if (type == "category") {
                categorywisebloglist(slug);
              } else {
                getBlogData();
              }
              latestBlogs();
      
    const getPageData = {
        slug: "blogs",
      };
    ApiService.postData("page-content", getPageData).then(
        (res) => {
            if (res.status === "success") {              
                setPageData(res.data);
                setHeaderImageUrl(res.page_header_image_path)
             
            }
    })}

    didMountRef.current = false;
  });

  const getBlogData = () => {
    ApiService.fetchData("blog-list").then((res) => {
      if (res.status === "success") {
        setBlogData(res.blogsData);
        setBlogImageUrl(res.blog_url);
      }
    });
  };

  const latestBlogs = () =>{
    ApiService.fetchData("blog-list-sidebar-data").then((res) =>{
      if (res.status === "success") {
        setLatestCategoryData(res.blogCategories);
        setLatestBlogData(res.latestblogdata);
        setLatestBlogImageUrl(res.blog_url);
        setBlogListDefaultImage(res.default_image_baseurl);
      }
    })
  }

  const categorywisebloglist = (category_slug) => {
    const dataString = {
      category_slug: category_slug,
    };

    ApiService.postData("category-wise-blogs-list", dataString).then((res) => {
        if (res?.status === "success") {
          setBlogData(res.blogsData);
          setBlogImageUrl(res.blog_url);
          setDefaultImage(res.default_image_baseurl);
        }
      }
    );
  };
    return (     
        <>
    <Helmet>
         <title>{pageData?.page_meta_title != null ? pageData.page_meta_title : "Hearts with Fingers"}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc :"Hearts with Fingers"} />
        {pageData.page_meta_keyword != null ?<meta name="keywords" content={pageData.page_meta_keyword} />:"Hearts with Fingers"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData?.page_meta_title != null ? pageData.page_meta_title : "Hearts with Fingers"} />
        <meta name="twitter:url" content={window.location.href} />
        {pageData?.page_header_image ? (
                    <meta property="og:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                    <meta
                        property="og:image"
                        content={constant.FRONT_URL + 'img/logo.png'}
                    />
                )}
        <meta property="og:url" content={window.location.href} />
        {pageData?.page_meta_desc != null ? (
                    <meta property="og:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="og:description" content="Hearts with Fingers" />
                )}
                
        <meta name="twitter:title" content={pageData?.page_meta_title != null ? pageData.page_meta_title : "Hearts with Fingers"}/>
        {pageData?.page_meta_desc != null ? (
                    <meta property="twitter:description" content={pageData?.page_meta_desc} />
                ) : (
                    <meta property="twitter:description" content="Hearts with Fingers" />
                )}
                     {pageData?.page_header_image ? (
                    <meta property="twitter:image" content={headerImageUrl + pageData.page_header_image} />
                ) : (
                    <meta
                        property="twitter:image"
                        content={constant.FRONT_URL + 'img/logo.png'}
                    />
                )}
        
      </Helmet>
        <Header state="inner-header" />
        <div className="subheader">
          <Container>
            <Row>
           
              <Col lg={12}>
                <h1>{pageData.page_name}</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                 
                  <Breadcrumb.Item active>{pageData.page_name}</Breadcrumb.Item>
                </Breadcrumb>
             
            
              </Col>
            </Row>
          </Container>
        </div>

        <section className="sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
            {blogData.map((value,index)=>( 
              <div className="bloglist" key={index}>
                <div className="row">
                  <div className="col-lg-4">
                    <figure className="bloglistMedia">
                    <a href={`/blogs/${value.blog_slug}`}>
                      <img src={value.blog_image != null ? blogImageUrl + "/" + value.blog_image : constant.DEFAULT_IMAGE} alt={value.blog_image}/>
                    </a>
                    </figure>
                  </div>
                  <div className="col-lg-8">
                    <div className="bloglistContent">
                      
                    <h2 class="title">
                    <a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a>
                      </h2>
                      <ul className="blog-meta">
            <li>
            <a href={`/blogs/${value.blog_slug}`}><i class="ri-calendar-line"></i>{moment(value.created_at).format('MMM D, YYYY')}</a>
            </li>
            <li><a href={`/blogs/${value.blog_slug}`}><i class="ri-user-line"></i>Admin</a></li>
            </ul>
            <div className="shortdesc">{value.blog_short_description}</div>
            <a href={`/blogs/${value.blog_slug}`} className="btn btn-primary-outline btn-medium mt-20">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
               ))} 
      
            </div>
            <div className="col-lg-4">
                <div className="blogsidebx">
                  <div className="blogsidebx-title">
                    <h2>Latest News & Blog</h2>
                  </div>
                  {latestBlogData.map((value) => (
                  <div className="sideblogbox">
                    <div className="sideblogboxMedia">
                    <img
                            src={
                              value.blog_image != null
                                ? latestBlogImageUrl + "/" + value.blog_image
                                : constant.DEFAULT_IMAGE
                            }
                            alt="Blog Images"
                          ></img>
                    </div>
                    <div className="sideblogboxContent">
                      <h2 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h2>
                     
                    </div>
                  </div>
                    ))}
                </div>
                <div className="blogsidebx">
                  <div className="blogsidebx-title">
                    <h2>Blog Categories</h2>
                  </div>
                  <ul className="bloglistcat">
                  {latestCategoryData.map((value) => (
                            <li
                            onClick={(e) =>
                              categorywisebloglist(value.category_slug)
                            }
                          >
                            <a href={`/blogs/category/` + value.category_slug}>
                            {value.category_name}

                            </a>
                          </li>


                          ))}
                
                  </ul>
                  </div>
            </div>
          </div>
        </div>
        </section>

        <Footer/>
        </>
    )
}
export default Blog
