import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import QuickViewModal from "../Modals/quick_view_modal";
import LoginModal from "../Modals/login_modal";
import StarRating from "./starrating";
import VariationModal from "../Modals/variation_modal";
import multiCurrency from "../../Components/Elements/multi_currrency";
import { showToast } from "./toastUtlis";
function ProductBox({ productValue, indexProduct }) {
    const didMountRef = useRef(true);
    const [productData, setProductData] = useState(null);
    const [showQuick, setShowQuick] = useState(false);
    const handleShowQuickModal = (data) => {
        setProductData(data);
        setShowQuick(true);
    };

    const handleChildQuickModalData = (status) => {
        setShowQuick(status);
    };
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleChildData = (status) => {
        setShow(status);
    };

    const [showVariation, setShowVariation] = useState(false);
    const handleChildVariationModalData = (status) => {
        setShowVariation(status);
    };
    const handleShowVariation = (data) => {
        setProductData(data);
        setShowVariation(true);
    };
    const [setSession, SetSession] = useState("");

    useEffect(() => {
        if (didMountRef.current) {
            SetSession(localStorage.getItem("USER_TOKEN"));
        }
        didMountRef.current = false;
    }, [productValue,indexProduct]);

    const addtofav = (productId) => {
        const dataString = {
            product_id: productId,
        };
        ApiService.postData("add-to-fav", dataString).then((res) => {
            if (res.data.status === "success") {
                if (res.data.notification === "ri-heart-fill") {
                    showToast('success', 'Added to Wishlist', 1000);
                } else {
                    showToast('success', 'Removed from Wishlist', 1000);
                }
                var element = document.getElementsByClassName("wishlisticon" + productId)[0];
                if (element) {
                    element.classList.remove("ri-heart-line", "ri-heart-fill");
                    element.classList.add(res.data.notification);
                }
            }
        });
    };
    let mrpValue = parseFloat(productValue.product_price);
    let sellingPriceValue = parseFloat(
        productValue.product_selling_price
    );
    let discount = 0;
    if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
      discount = (
        ((mrpValue - sellingPriceValue) / mrpValue) *
        100
      ).toFixed(2);
    } 
    return (
        <>
        
            <div className="cproduct" key={indexProduct}>
                <a href={"/product/" + productValue.product_slug} className="cproduct-media">
                    <figure className="cproduct-media-img">
                        <img src={productValue.product_image != null ? productValue.product_image : constant.DEFAULT_IMAGE} alt={productValue.product_name} title={productValue.product_name} width='280' height='315'></img>
                    </figure>
                    {productValue.product_label_name && productValue.product_label_name !== "" ? (
                        <div className="cproduct-label-group">
                            {productValue.product_label_name.split(", ").map((tagvalue, indextag) => {
                                return (
                                    <label className="cproduct-label label-sale" key={indextag}>{tagvalue}</label>
                                );
                            })}
                        </div>
                    ) : null}
                    <div className="cproduct-action-vertical">
                        {setSession ? (
                            productValue.ufp_id && productValue.ufp_id > 0 ? (
                                <a href="javascript:void(0)" className="cpav-button" onClick={(e) => addtofav(productValue.product_id)}><i className={"ri-heart-3-fill wishlisticon" + productValue.product_id}></i></a>
                            ) : (
                                <a href="javascript:void(0)" className="cpav-button" onClick={(e) => addtofav(productValue.product_id)}><i className={"ri-heart-3-line wishlisticon" + productValue.product_id}></i></a>
                            )
                        ) : (
                            <a href="javascript:void(0)" className="cpav-button" onClick={handleShow}><i className={"ri-heart-3-line wishlisticon" + productValue.product_id}></i></a>
                        )}
                    </div>
                    <a href="javascript:;" className="quickviewbutton" onClick={() => { handleShowQuickModal(productValue); }}>Quick View</a>
                </a>
                <div className="cproduct-details">
                    <h2 className="cproduct-name"><a href={"/product/" + productValue.product_slug}>{productValue.product_name}</a></h2>
                    {productValue.has_many_reviews && productValue.has_many_reviews.length > 0 && productValue.product_rating &&
                        productValue.product_rating > 0 ? (
                        <div className="cratings-container"><StarRating numberOfStars={productValue.product_rating} reviewCount={productValue.has_many_reviews.length} /></div>
                    ) : (
                        ""
                    )}
                    <div className="cproduct-price">
                        <ins className="new-price">{multiCurrency(productValue.product_selling_price)}</ins>
                        {discount > 0 ? (
                            <>
                                <del className="old-price">{multiCurrency(productValue.product_price)}</del>
                                <span className="off-price">{discount}% Off</span>
                            </>
                        ) : null}
                    </div>

                </div>
            </div>
            {show && <LoginModal showmodal={show} onChildData={handleChildData} />}
            {showQuick && (
                <QuickViewModal
                    showmodal={showQuick}
                    productdata={productData}
                    onChildData={handleChildQuickModalData}
                />
            )}
            {showVariation && (
                <VariationModal
                    showvariationmodal={showVariation}
                    productdata={productData}
                    onChildData={handleChildVariationModalData}
                />
            )}
        </>
    );
}
export default ProductBox;
