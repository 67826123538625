import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
import { showToast } from "./toastUtlis";
function BlogHtml({ indexLoop, onParentData, dataObj }) {
    const didMountRef = useRef(true);
    const [loading, setLoading] = useState();
    const [firstBlogData, setFirstBlogData] = useState({});
    const [blogData, setBlogData] = useState([]);
    const [blogImagePath, SetBlogImagePath] = useState("");

    useEffect(() => {
        if (didMountRef.current) {
            getBlogsData()
        }
        didMountRef.current = false;
    }, []);

    const getBlogsData = () => {
        setLoading(true);
        ApiService.fetchData("getBlogsData").then((res) => {
            if (res.status == "success") {
                SetBlogImagePath(res.blog_image_path)
                setBlogData(res.blogsData);
                setFirstBlogData(res.firstBlogsData);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }
    return (
        <>
            {loading == true ? (
                <>
                    <section className="section-gap-large">
                        <div className="section-title tx-center mb-40">
                            <span className="pre-title"><Skeleton height={30} width={150} /></span>
                            <h2><Skeleton height={30} width={250} /></h2>
                        </div>
                        <div className="blog-section-1">
                            <div className="container">
                                <div className="row g-2">
                                    <div className="col-lg-6">
                                        <div className="edu-blog blog-style-1 first-large-blog">
                                            <div className="inner">
                                                <div className="thumbnail">
                                                    <Skeleton style={{ width: '100%' }} height={400} />
                                                </div>
                                                <div className="content">
                                                    <Skeleton height={30} width={500} />
                                                    <Skeleton height={30} width={400} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        {[1, 2].map((_, index) => (
                                            <div className="edu-blog blog-style-1" key={index}>
                                                <div className="inner">
                                                    <div className="thumbnail">
                                                        <Skeleton width={200} height={200} />
                                                    </div>
                                                    <div className="content">
                                                        <h2 className="title"><Skeleton height={30} width={250} /></h2>
                                                        <p><Skeleton height={30} width={250} /></p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                <>
                    {firstBlogData && firstBlogData.blog_id > 0 && blogData && blogData.length > 0 ?
                        <>
                            <div className="section-title tx-center mb-40">
                                {dataObj.home_blog_title && (
                                    <span className="pre-title">{dataObj.home_blog_title}</span>
                                )}
                                {dataObj.home_blog_title && (<h2>{dataObj.home_blog_sub_title}</h2>)}
                            </div>
                            <div className="blog-section-1">
                                
                                    <div className="row g-2">
                                        <div className="col-lg-6">
                                            <div className="edu-blog blog-style-1 first-large-blog">
                                                <div className="inner">
                                                    <div className="thumbnail">
                                                        <a href={"/blogs/" + firstBlogData.blog_slug}>
                                                            <img src={firstBlogData.blog_image ? blogImagePath + '/' + firstBlogData.blog_image : constant.DEFAULT_IMAGE} alt="heartswithfingersblogs"></img>
                                                        </a>
                                                    </div>
                                                    <div className="content">
                                                        <h2 className="title"><a href={"/blogs/" + firstBlogData.blog_slug}>{firstBlogData.blog_name}</a></h2>
                                                        <p>{firstBlogData.blog_short_description} </p>
                                                        <a href={"/blogs/" + firstBlogData.blog_slug} className="btn-primary-outline btn btn-medium mt-2">Learn More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {blogData.map((value, index) => {
                                                return (
                                                    <>
                                                        <div className="edu-blog blog-style-1" key={index}>
                                                            <div className="inner">
                                                                <div className="thumbnail">
                                                                    <a href={"/blogs/" + value.blog_slug}>
                                                                        <img src={value.blog_image ? blogImagePath + '/' + value.blog_image : constant.DEFAULT_IMAGE} alt="heartswithfingersblogs"></img>
                                                                    </a>
                                                                </div>
                                                                <div className="content">
                                                                    <h2 className="title"><a href={"/blogs/" + value.blog_slug}>{value.blog_name}</a></h2>
                                                                    <p>{value.blog_short_description} </p>
                                                                    <a href={"/blogs/" + value.blog_slug} className="btn-primary-outline btn btn-medium mt-2">Learn More</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                
                            </div>
                   </>
                        : null
                    }
                </>
            )}
        </>
    );
}
export default BlogHtml;
