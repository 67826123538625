import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import StarRating from "./starrating";
import multiCurrency from "../../Components/Elements/multi_currrency";
import Skeleton from "react-loading-skeleton";
function VideoProductsHtml({ indexLoop, onParentData, dataObj }) {
    const didMountRef = useRef(true);
    const [homeCategoriesData, setHomeCategoriesData] = useState([]);
    const [loading, setLoading] = useState();
    useEffect(() => {
        if (didMountRef.current) {
            getHomeCategoryData();
        }
        didMountRef.current = false;
    }, [homeCategoriesData]);
    const getHomeCategoryData = () => {
        setLoading(true);
        const dataString = {
            data: dataObj,
        }
        ApiService.postData("home-video-products-data", dataString).then((res) => {
            if (res.status == "success") {
                setHomeCategoriesData(res.resCategoryData);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    };

    return (
        <>
            {loading == true ? (
                <>
                    <BrowserView>
                        <div className="row g-2">
                            {[...Array(4)].map((_, index) => (
                                <div key={index} className="col-lg-3 col-6">
                                    <div className="product">
                                        <figure className="product-media">
                                            <Skeleton variant="text" width={300} height={250} />
                                        </figure>
                                        <div className="product-details">
                                            <h3 className="product-name">
                                                <Skeleton variant="text" width={150} />
                                            </h3>
                                            <div className="product-price">
                                                <Skeleton variant="text" width={150} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className="row g-2">
                            {[...Array(4)].map((_, index) => (
                                <div key={index} className="col-lg-3 col-6">
                                    <div className="product">
                                        <figure className="product-media">
                                            <Skeleton variant="text" width={300} height={160} />
                                        </figure>
                                        <div className="product-details">
                                            <h3 className="product-name">
                                                <Skeleton variant="text" width={150} />
                                            </h3>
                                            <div className="product-price">
                                                <Skeleton variant="text" width={150} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </MobileView>
                </>
            ) : (
                <>
                    {
                        homeCategoriesData.length > 0 && dataObj.home_display_type === 2 ? (
                            <>
                                <div className="page-title page-title-center mb-4">
                                    <h2 className="mb-0">{dataObj.home_product_name}</h2>
                                </div>
                                <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                                    {homeCategoriesData.map((subvalue, indexProduct) => {
                                        let mrpValue = parseFloat(subvalue.product_price);
                                        let sellingPriceValue = parseFloat(
                                            subvalue.product_selling_price
                                        );
                                        let discount = 0;
                                        if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
                                            discount = (
                                                ((mrpValue - sellingPriceValue) / mrpValue) *
                                                100
                                            );
                                        } else {
                                            discount = 0;
                                        }
                                        return (
                                            <div className="cproduct cproductVideo" key={indexProduct}>
                                                <a href={"/product/" + subvalue.product_slug} className="cproduct-media">
                                                    <figure className="cproduct-media-img">
                                                        {subvalue.product_video ? (

                                                            <video
                                                                src={subvalue.product_video}
                                                                autoPlay="autoplay"
                                                                loop
                                                                muted
                                                                playsInline
                                                            ></video>

                                                        ) : (
                                                            <img
                                                                src={
                                                                    subvalue.product_image != null
                                                                        ? subvalue.product_image
                                                                        : constant.DEFAULT_IMAGE
                                                                }
                                                                alt={subvalue.product_name}
                                                                width="280"
                                                                height="315"
                                                            />
                                                        )}
                                                    </figure>
                                                </a>
                                                <div className="cproduct-details">
                                                    <h2 className="cproduct-name">
                                                        <a href={"/product/" + subvalue.product_slug}>
                                                            {subvalue.product_name}
                                                        </a>
                                                    </h2>
                                                    {subvalue.has_many_reviews && subvalue.has_many_reviews.length > 0 && subvalue.product_rating &&
                                                        subvalue.product_rating > 0 ? (
                                                        <div className="cratings-container"><StarRating numberOfStars={subvalue.product_rating} reviewCount={subvalue.has_many_reviews.length} /></div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div className="cproduct-price">
                                                        <ins className="new-price">
                                                            {multiCurrency(subvalue.product_selling_price)}
                                                        </ins>
                                                        {discount > 0 ? (
                                                            <>
                                                                <del className="old-price">
                                                                    {multiCurrency(subvalue.product_price)}
                                                                </del>
                                                                <span className="off-price">{Math.round(discount)}% Off</span>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : homeCategoriesData.length > 0 && dataObj.home_display_type === 1 ? (
                            <>
                                <div className="page-title mb-4 mt-4">
                                    <h2 className="mb-0">{dataObj.home_product_name}</h2>
                                </div>
                                <Swiper
                                    loop={true}
                                    spaceBetween={15}
                                    navigation={{
                                        nextEl: ".swiper-button-next",
                                        prevEl: ".swiper-button-prev",
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                        600: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                        1000: {
                                            slidesPerView: dataObj.home_column,
                                        },
                                    }}>
                                    {homeCategoriesData.map((subvalue, indexProduct) => {
                                        let mrpValue = parseFloat(subvalue.product_price);
                                        let sellingPriceValue = parseFloat(
                                            subvalue.product_selling_price
                                        );
                                        let discount = 0;
                                        if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
                                            discount = (
                                                ((mrpValue - sellingPriceValue) / mrpValue) *
                                                100
                                            );
                                        } else {
                                            discount = 0;
                                        }
                                        return (
                                            <SwiperSlide key={indexProduct}>
                                                <div className="cproduct" key={indexProduct}>
                                                    <a href={"/product/" + subvalue.product_slug} className="cproduct-media">
                                                        <figure className="cproduct-media-img">
                                                            {subvalue.product_video ? (
                                                                <a href={"/product/" + subvalue.product_slug}>
                                                                    <video
                                                                        src={subvalue.product_video}
                                                                        autoPlay="autoplay"
                                                                        loop
                                                                        muted
                                                                        playsInline
                                                                    ></video>
                                                                </a>
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        subvalue.product_image != null
                                                                            ? subvalue.product_image
                                                                            : constant.DEFAULT_IMAGE
                                                                    }
                                                                    alt={subvalue.product_name}
                                                                    width="280"
                                                                    height="315"
                                                                />
                                                            )}
                                                        </figure>
                                                    </a>
                                                    <div className="product-details">
                                                        <h3 className="product-name">
                                                            <a href={"/product/" + subvalue.product_slug}>
                                                                {subvalue.product_name}
                                                            </a>
                                                        </h3>
                                                        {subvalue.has_many_reviews && subvalue.has_many_reviews.length > 0 && subvalue.product_rating &&
                                                            subvalue.product_rating > 0 ? (
                                                            <div className="cratings-container"><StarRating numberOfStars={subvalue.product_rating} reviewCount={subvalue.has_many_reviews.length} /></div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="product-price">
                                                            <ins className="new-price">
                                                                {multiCurrency(subvalue.product_selling_price)}
                                                            </ins>
                                                            {discount > 0 ? (
                                                                <>
                                                                    <del className="old-price">
                                                                        {multiCurrency(subvalue.product_price)}
                                                                    </del>
                                                                    <span className="off">{Math.round(discount)}% Off</span>
                                                                </>
                                                            ) : null}
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </>
                        ) : null
                    }

                </>
            )}
        </>
    );
}
export default VideoProductsHtml;
