import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function Offers() {
  return (
    <>
    <Header/>
    <div>Offers</div>
    <Footer/>
    </>
  )
}

export default Offers