import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import constant from "../../Components/Services/constant";
import moment from "moment";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
function BlogsDetails() {
  const { slug } = useParams();
  const [blogDetailData, setBlogDetailData] = useState({});
  const [blogData, setBlogData] = useState([]);
  const [defaultImage, setDefaultImage] = useState("");
  const [blogCategoryData, setBlogCategoryData] = useState([]);
  const [blogImageUrl, setBlogImageUrl] = useState("");
  const [latestBlogData, setLatestBlogData] = useState([]);
  const [latestCategoryData, setLatestCategoryData] = useState([]);
  const [latestBlogImageUrl, setLatestBlogImageUrl] = useState("");
  const [blogListDefaultImage, setBlogListDefaultImage] = useState("");
  const [loading, setLoading] = useState();

  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getBlogData();
      latestBlogs();
    }

    didMountRef.current = false;
  });


  
  const getBlogData = () => {
    const getBlogDetail = {
      blog_slug: slug,
    };
    setLoading(true);
    ApiService.postData("blog-details", getBlogDetail).then((res) => {
      if (res.status === "success") {
        setLoading(false);
        setBlogDetailData(res.data);
        setBlogImageUrl(res.blog_image_path);
        setBlogCategoryData(res.categoryData);
      } else {
        setLoading(false);
      }
    });
  };

  const latestBlogs = () =>{
    ApiService.fetchData("blog-list-sidebar-data").then((res) =>{
      if (res.status === "success") {
        setLatestCategoryData(res.blogCategories);
        setLatestBlogData(res.latestblogdata);
        setLatestBlogImageUrl(res.blog_url);
        setBlogListDefaultImage(res.default_image_baseurl);
      }
    })
  }
  const categorywisebloglist = (category_slug) => {
    const dataString = {
      category_slug: category_slug,
    };
    setLoading(true);
    ApiService.Commanpostrequest("category-wise-blogs-list", dataString).then(
      (res) => {
        if (res?.status === "success") {
          setBlogData(res.blogsData);
          setBlogImageUrl(res.blog_url);
          setDefaultImage(res.default_image_baseurl);
          setLoading(false);
        }
      }
    );
  };
  return (
    <>
      <Helmet>
        <title>{blogDetailData.blog_meta_title}</title>
        <meta name="description" itemprop="description" content={blogDetailData.blog_meta_desc != null ? blogDetailData.blog_meta_desc : "Hearts with Fingers"} />
        {blogDetailData.blog_meta_keyword != null ? <meta name="keywords" content={blogDetailData.blog_meta_keyword} /> : "Hearts with Fingers"}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={blogDetailData?.blog_meta_title != null ? blogDetailData.blog_meta_title : "Hearts with Fingers"} />
        <meta name="twitter:url" content={window.location.href} />
        {blogDetailData?.blog_image ? (
                    <meta property="og:image" content={blogImageUrl + blogDetailData.blog_image} />
                ) : (
                    <meta
                        property="og:image"
                        content={constant.FRONT_URL + 'img/logo.png'}
                    />
                )}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={blogDetailData.blog_meta_desc != null ? blogDetailData.blog_meta_desc : "Hearts with Fingers"} />
        <meta property="twitter:title" content={blogDetailData?.blog_meta_title != null ? blogDetailData.blog_meta_title : "Hearts with Fingers"} />
        <meta name="twitter:description" content={blogDetailData.blog_meta_desc != null ? blogDetailData.blog_meta_desc : "Hearts with Fingers"} />
        {blogDetailData?.blog_image ? (
                    <meta property="twitter:image" content={blogImageUrl + blogDetailData.blog_image} />
                ) : (
                    <meta
                        property="twitter:image"
                        content={constant.FRONT_URL + 'img/logo.png'}
                    />
                )}
      </Helmet>
      <Header state="inner-header" />
      {loading === true ? (
        <>
          <section className="sec-pad">
            <Container>
              <Row>
                <Col lg={8}>
                  <div className="listbog-details">
                    <div className="thumbnail">
                      <Skeleton className="skeleton" style={{ width: '100%' }} height={350} />
                    </div>
                    <div className="content">
                        <Skeleton className="skeleton" height={30} width={400}/>
                        <Skeleton className="skeleton" height={30} width={250}/>
                      <div className="desc">
                        <Skeleton className="skeleton" height={100} count={3} />
                      </div>
                    </div>
                  </div>
                </Col> 
              </Row>
            </Container>
          </section>
        </>
      ) : (
        <>
          <section className="sec-pad">
            <Container>
              <Row>
              <div className="col-lg-8">
                  <div className="listbog-details">
                    <div className="thumbnail">
                      {
                        blogDetailData.blog_image != null ?
                          <img
                            src={
                              blogDetailData.blog_image != null
                                ? blogImageUrl + "/" + blogDetailData.blog_image
                                : constant.DEFAULT_IMAGE
                            }
                            alt={blogDetailData.blog_image}
                          />
                          : ""}
                    </div>
                    <div className="content">
                      {
                        blogDetailData.blog_name != null ?
                          <h1>{blogDetailData.blog_name}</h1>
                          : ""}
                      <ul className="blog-meta mb-3">
                        <li><i className="fas fa-calendar-alt"></i> {moment(blogDetailData.created_at).format("DD-MM-YYYY")}</li>
                        <li><i className="fas fa-user"></i>Admin</li>
                      </ul>
                      <div className="desc">
                        {blogDetailData.blog_desc != null ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: blogDetailData.blog_desc,
                            }}
                          ></div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                <div className="blogsidebx">
                  <div className="blogsidebx-title">
                    <h2>Latest News & Blog</h2>
                  </div>
                  {latestBlogData.map((value) => (
                  <div className="sideblogbox">
                    <div className="sideblogboxMedia">
                    <img
                            src={
                              value.blog_image != null
                                ? latestBlogImageUrl + "/" + value.blog_image
                                : constant.DEFAULT_IMAGE
                            }
                            alt="Blog Images"
                          ></img>
                    </div>
                    <div className="sideblogboxContent">
                      <h2 className="title"><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h2>
                     
                    </div>
                  </div>
                    ))}
                </div>
                <div className="blogsidebx">
                  <div className="blogsidebx-title">
                    <h2>Blog Categories</h2>
                  </div>
                  <ul className="bloglistcat">
                  {latestCategoryData.map((value) => (
                            <li
                            onClick={(e) =>
                              categorywisebloglist(value.category_slug)
                            }
                          >
                            <a href={`/blogs/category/` + value.category_slug}>
                            {value.category_name}

                            </a>
                          </li>


                          ))}
                
                  </ul>
                  </div>
            </div>
              </Row>
            </Container>
          </section>
        </>
      )}
      <Footer />
    </>
  );
}
export default BlogsDetails;
