import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

function Gifting() {
  return (
    <>
    <Header/>
    <div>Gifting</div>
    <Footer/>
    </>
  )
}

export default Gifting